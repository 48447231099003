<template>
  <v-row class="pl-3">
    <v-col class="pt-0 pl-0 pb-0">
      <v-tabs
        v-if="sortedTabs.length > 1"
        background-color="transparent"
        class="hb-text-light"
        color="#101318"
      >
        <v-tabs-slider color="#00848E"></v-tabs-slider>
        <v-tab
          class="text-capitalize"
          v-for="(item, index) in sortedTabs"
          :key="index"
          @click="onTabClick(item)"
          >{{ item.group.tab_name }}</v-tab
        >
      </v-tabs>
      <v-divider v-if="sortedTabs.length > 1" />
      <component
        :is="activeTab.component"
        :component="activeTab.key"
        :owner="companyDetails.gds_owner_id"
        :base-url="baseUrl"
        :token="platformAppToken"
        :app-list="appList"
        :api-url="apiUrl"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TabViewList",
  data() {
    return {
      activeTab: "",
    };
  },
  props: {
    tabGroups: {
      type: Array,
      required: true,
      default: [],
    },
  },
  mounted() {
    this.activeTab = this.sortedTabs[0];
  },
  computed: {
    ...mapGetters({
      cmsAppId: "authenticationStore/getCmsAppId",
      platformAppToken: "authenticationStore/getPlatformAppToken",
      companyDetails: "authenticationStore/getCompany",
      appIdList: "authenticationStore/getApplications",
      properties: "propertiesStore/filtered",
    }),
    appList() {
      return JSON.stringify(this.appIdList);
    },
    selectedProperties() {
      return JSON.stringify(this.properties);
    },
    baseUrl() {
      return process.env.VUE_APP_GDS_BASE_URL;
    },
    apiUrl() {
      let url =
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_SUBDOMAIN +
        "." +
        process.env.VUE_APP_DOMAIN +
        ":" +
        process.env.VUE_APP_API_PORT;
      return url;
    },
    sortedTabs() {
      let sortedGroups = [];
      for (let item of this.tabGroups) {
        sortedGroups.push(item);
      }
      sortedGroups.sort((a, b) =>
        a?.group?.tab_name.localeCompare(b?.group?.tab_name)
      );
      return sortedGroups;
    },
  },
  methods: {
    onTabClick(tabSection) {
      this.activeTab = tabSection;
    },
  },
};
</script>